import React from "react";

export default function FilterSelect({i18n, enabled, legend, items = [], label = i => i.name, type='checkbox',
                          name = i => i.id, checked = i => i.enabled, setter, onClick, className, itemType}) {
    const nrEnabled = items.filter((item) => item.enabled).length;
    const allEnabled = nrEnabled == items.length;
    const selectAll = e => {
        setter(items.map(i => Object.assign({}, i, {enabled: !allEnabled})));
    }
    return (
        <div className={className}>
            {enabled &&
                <fieldset>
                    {type === 'checkbox' && setter && items.length > 1 &&
                        <div className="ms-3 mt-1 mb-3">
                            <label>
                                <input type={type}
                                       name={'select.all'}
                                       checked={allEnabled}
                                       onChange={selectAll}>
                                </input>
                                &nbsp; {i18n('filter.select.all')}
                            </label>
                        </div>
                    }
                    <ul className="list-unstyled ms-3">
                        {items.map((item, index) =>
                            <li key={index} className={item.enabled ? 'nobullet' : ''}>
                                {/*
                         <button id={'item-' + itemType + '-' + item.id} className={item.enabled ? 'button-text button-icon icon-clear' : 'button-text button-icon icon-add'} onClick={(e) => onClick(e, item, nrEnabled)}>{label(item)}<span
                             className="visually-hidden">{i18n('click.to.choose.filter')} {index + 1}</span></button>
                             */}
                                <label>
                                    <input type="checkbox"
                                           id={'item-' + itemType + '-' + item.id}
                                           checked={checked(item)}
                                           value={item.id}
                                           onChange={(e) => onClick(e, item, nrEnabled)}>
                                    </input>
                                    &nbsp; {label(item)}
                                </label>
                            </li>
                        )}
                    </ul>
                </fieldset>
            }
        </div>
    );
}