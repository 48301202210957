import React, {useEffect, useState} from 'react';
import {useXhr} from '../util/react-util';
import FilterSelect from "./FilterSelect";
import FilterSelectGroupCategory from "./FilterSelectGroupCategory";

/**
 * Component for distribute groups and sections
 */
export default function GroupHandler ({i18n, groups, sections, groupssets, participationState}) {

    const {participations, setParticipations} = participationState;
    {/* Distribute from group, groupset or section */}
    const [distributeGroup, setDistributeGroup] = useState(false);
    const [distributeSection, setDistributeSection] = useState(false);
    const [filterSections, setFilterSections] = useState(sections.map(s=>Object.assign({}, s, {enabled:false})));
    const [filterToSections, setFilterToSections] = useState(
        sections
            .filter(s => !/^[^:]+:[^:]+:[^:]+$/.test(s.name)) // Filter out sections that match the pattern for a LADOK section
            .map(s => Object.assign({}, s, { enabled: false }))
    );

    {/* Distribute to group, groupset or sections */}
    const [distributeToGroups, setDistributeToGroups] = useState(false);
    const [distributeToSections, setDistributeToSections] = useState(false);

    const repo = useXhr();

    function getCheckedCheckboxes(checkboxes) {
        let checkedBoxes = [];
        checkboxes.forEach(checkbox => {
            if (checkbox.checked) {
                checkedBoxes.push(checkbox.value);
            }
        });
        return checkedBoxes;
    }

    function showErrorMessage(type) {
        document.getElementById('save-text').style.display = 'none';
        if (type === 'from') {
            document.getElementById('failed-message-from').style.display = 'block';
        } else {
            document.getElementById('failed-message-to').style.display = 'block';
        }
    }

    const saveDistribution = (e) => {
        e.preventDefault();
        document.getElementById('save-text').style.display = 'none';
        document.getElementById('failed-message').style.display = 'none';
        document.getElementById('failed-message-from').style.display = 'none';
        document.getElementById('failed-message-to').style.display = 'none';
        document.getElementById('success-message').style.display = 'none';

        let distribution = {};
        {/* Distribute from group or section */}
        if (! distributeGroup && ! distributeSection) {
            showErrorMessage("from");
            return;
        }
        if (distributeGroup) {
            distribution.distributegroup = true;
            let checkboxes = document.querySelectorAll('[id^="fromgroup-item-group-"]');
            distribution.fromGroups = getCheckedCheckboxes(checkboxes);
            if (distribution.fromGroups.length === 0) {
                showErrorMessage('from');
                return;
            }
        }
        if (distributeSection) {
            distribution.distributegroup = false;
            let checkboxes = document.querySelectorAll('[id^="item-fromsection-"]');
            distribution.fromSections = getCheckedCheckboxes(checkboxes);
            if (distribution.fromSections.length === 0) {
                showErrorMessage('from');
                return;
            }
        }
        if (! distributeToGroups && ! distributeToSections) {
            showErrorMessage("to");
            return;
        }
        if (distributeToGroups) {
            let checkboxes = document.querySelectorAll('[id^="togroup-item-group-"]');
            distribution.toGroups = getCheckedCheckboxes(checkboxes);
            if (distribution.toGroups.length === 0) {
                showErrorMessage('to');
                return;
            }
        }
        if (distributeToSections) {
            let checkboxes = document.querySelectorAll('[id^="item-tosection-"]');
            distribution.toSections = getCheckedCheckboxes(checkboxes);
            if (distribution.toSections.length === 0) {
                showErrorMessage('to');
                return;
            }
        }
        distribution.reloadParticipations = document.getElementById('reloadParticipations').checked;
        document.getElementById('save-text').style.display = 'block';
        document.getElementById('spinner-border').style.display = 'block';
        //console.log("Save distribution", distribution);
        repo.post('/savegroupdistribution',
            distribution,
            (r) => {
                if (distribution.reloadParticipations) {
                    setParticipations(r.participations);
                }
                document.getElementById('save-text').style.display = 'none';
                document.getElementById('success-message').style.display = 'block';
                document.getElementById('spinner-border').style.display = 'none';
            },
            (response, errorCode) => {
                console.log("save group distribution failed ", response, errorCode)
                document.getElementById('save-text').style.display = 'none';
                document.getElementById('spinner-border').style.display = 'none';
                document.getElementById('failed-message').style.display = 'block';
            });
    }
    const toggleGroupsSections = (distributeFrom) => {
        setDistributeGroup(distributeFrom === 'group');
        setDistributeSection(distributeFrom === 'section');
    }

    const toggleDistributeTo = (distributeTo) => {
        setDistributeToGroups(distributeTo === 'group');
        setDistributeToSections(distributeTo === 'section');
    }
    const onSectionSelect = (e, s, nrEnabled) => {
        if (nrEnabled <= 1 && s.enabled) {
            //setFilterSectionsEnabled(false);
            setFilterSections(filterSections.map(fs => Object.assign({}, fs, {enabled: false})))
        } else {
            //setFilterSectionsEnabled(true);
            setFilterSections(filterSections.map(fs => (fs.id !== s.id) ? fs : Object.assign({}, s, {enabled: !s.enabled})));
        }
    };
    const [filterGroupCategory, setFilterGroupCategory] = useState(
        groupssets.map(g => ({gc: g, enabled:true}))
    );
    /* Keep track if (from) groupcategory is open or closed */
    const [openGroupCategory, setOpenGroupCategory] = useState(false);
    const [filterGroups, setFilterGroups] = useState();
    const [filterGroupsEnabled, setFilterGroupsEnabled] = useState(false);
    const [filterSelectedGroups, setFilterSelectedGroups] = useState([]);

    const onGroupCategorySelect = (gc) => e => {
        e.preventDefault();
        if (filterGroupCategory.id === gc.id) {
            setOpenGroupCategory(openGroupCategory => ! openGroupCategory);
            if (openGroupCategory) {
                setFilterGroupsEnabled(true);
            }
        } else {
            setOpenGroupCategory(true);
            setFilterGroupsEnabled(true);
            setFilterGroupCategory(gc);
            setFilterGroups(gc && groups.filter(g => g.group_category_id === gc.id)
                .map(g => Object.assign({}, g, {enabled: filterSelectedGroups.some(e => e.id === g.id)})));
        }
    };
    const addGroupFilter = group => {
        if (group == null || group === 'undefined') {
            return;
        }
        group.enabled = true;
        if (! filterSelectedGroups.some(e => e.id === group.id)) {
            filterSelectedGroups.push(group);
            setFilterSelectedGroups(filterSelectedGroups);
        }
    }
    const deleteGroupFilter = group => {
        group.enabled = false;
        let index = filterSelectedGroups.map(g => g.id).indexOf(group.id);
        filterSelectedGroups.splice(index, 1)
        setFilterSelectedGroups(filterSelectedGroups);
    }

    const onGroupSelect = (e, group, nrEnabled) => {
        let isGroupEnabled = group.enabled;
        isGroupEnabled ? deleteGroupFilter(group) : addGroupFilter(group);
        if (nrEnabled <= 1 && isGroupEnabled) {
            setFilterGroups(
                filterGroups.map(fg => (fg.id !== group.id) ? fg : Object.assign({}, group, {enabled: false})));
            if (filterSelectedGroups.length === 0) {
                /* If no group selected - disable the group filter */
                setFilterGroupsEnabled(false);
                //setOpenGroupCategory(true);
            }
        } else {
            setFilterGroupsEnabled(true);
            setFilterGroups(
                filterGroups.map(fg => (fg.id !== group.id) ? fg : Object.assign({}, group, {enabled: ! isGroupEnabled})));
        }
    };
    {/* From groups or sections */ }
    useEffect(() => {
        let collapseElement;
        if (distributeGroup) {
            // If distributeGroup is selected, show the collapse
            collapseElement = document.getElementById('group-filter-from');
        }
        if (distributeSection) {
            // If distributeSection is selected, show the collapse
            collapseElement = document.getElementById('section-filter-from');
        }
        if (distributeGroup || distributeSection) {
            //Add class 'show' to the collapse element
            collapseElement.classList.add('show');
        }
    }, [distributeGroup, distributeSection]);
    {/* To groups or sections */ }
    useEffect(() => {
        let collapseElement;
        if (distributeToGroups) {
            collapseElement = document.getElementById('group-filter-to');
        }
        if (distributeToSections) {
            collapseElement = document.getElementById('section-filter-to');
        }
        if (distributeToGroups || distributeToSections) {
            //Add class 'show' to the collapse element
            collapseElement.classList.add('show');
        }
    }, [distributeToGroups, distributeToSections]);

    const [filterToGroupCategory, setFilterToGroupCategory] = useState(
        groupssets.map(g => ({gc: g, enabled:true}))
    );
    /* Keep track if (to) groupcategory is open or closed */
    const [openToGroupCategory, setOpenToGroupCategory] = useState(false);
    const [filterToGroups, setFilterToGroups] = useState();
    const [filterToGroupsEnabled, setFilterToGroupsEnabled] = useState(false);
    const [filterToSelectedGroups, setFilterToSelectedGroups] = useState([]);
    const onToGroupCategorySelect = (gc) => e => {
        e.preventDefault();
        if (filterToGroupCategory.id === gc.id) {
            setOpenToGroupCategory(openToGroupCategory => ! openToGroupCategory);
            if (openToGroupCategory) {
                setFilterToGroupsEnabled(true);
            }
        } else {
            setOpenToGroupCategory(true);
            setFilterToGroupsEnabled(true);
            setFilterToGroupCategory(gc);
            setFilterToGroups(gc && groups.filter(g => g.group_category_id === gc.id)
                .map(g => Object.assign({}, g, {enabled: filterToSelectedGroups.some(e => e.id === g.id)})));
        }
    };
    const onToGroupSelect = (e, group, nrEnabled) => {
        let isGroupEnabled = group.enabled;
        isGroupEnabled ? deleteToGroupFilter(group) : addToGroupFilter(group);
        if (nrEnabled <= 1 && isGroupEnabled) {
            setFilterToGroups(
                filterToGroups.map(fg => (fg.id !== group.id) ? fg : Object.assign({}, group, {enabled: false})));
            if (filterToSelectedGroups.length === 0) {
                /* If no group selected - disable the group filter */
                setFilterToGroupsEnabled(false);
            }
        } else {
            setFilterToGroupsEnabled(true);
            setFilterToGroups(
                filterToGroups.map(fg => (fg.id !== group.id) ? fg : Object.assign({}, group, {enabled: ! isGroupEnabled})));
        }
    };
    const addToGroupFilter = group => {
        if (group == null || group === 'undefined') {
            return;
        }
        group.enabled = true;
        if (! filterToSelectedGroups.some(e => e.id === group.id)) {
            filterToSelectedGroups.push(group);
            setFilterToSelectedGroups(filterToSelectedGroups);
        }
    }
    const deleteToGroupFilter = group => {
        group.enabled = false;
        let index = filterToSelectedGroups.map(g => g.id).indexOf(group.id);
        filterToSelectedGroups.splice(index, 1)
        setFilterToSelectedGroups(filterToSelectedGroups);
    }
    const onToSectionSelect = (e, s, nrEnabled) => {
        if (nrEnabled <= 1 && s.enabled) {
            //setFilterSectionsEnabled(false);
            setFilterToSections(filterToSections.map(fs => Object.assign({}, fs, {enabled: false})))
        } else {
            //setFilterSectionsEnabled(true);
            setFilterToSections(filterToSections.map(fs => (fs.id !== s.id) ? fs : Object.assign({}, s, {enabled: !s.enabled})));
        }
    };
    {/*<
    const sendChatMessage = (e) => {
        e.preventDefault();
        let message = document.getElementById('chat-message').value;
        let chatMessage = {
            prompt_message: message,
            temperature: 0,
            stream: false
        };

        repo.post('/chat',
            chatMessage,
            (r) => {
                document.getElementById('chat-answer').innerText = r.response;
            },
            (response, errorCode) => {
                console.log("sendChatMessage failed", response, errorCode)
            });
    }
    */}

    return (
        <div className={'form-group'}>
            {/*<h3 className={"form-label"}>{i18n('grouphandler.button')}</h3>*/}
            <div className="row enrollment-group-div">
                <label className={"form-label"}><strong>1.</strong> {i18n('grouphandler.select-group-or-section')}</label>
            </div>
            <div className={"distribute-from-choice"}>
            {/* The group filter */}
            <div className={'form-check distribute-from'}>
                <input id="group" name="group-or-section" className={'form-check-input'} type="radio"
                       data-bs-toggle="collapse"
                       data-bs-target="#group-filter-from"
                       aria-controls="group-filter-from"
                       aria-expanded={distributeGroup ? 'true' : 'false'}
                       value="group_selected" onChange={event => toggleGroupsSections('group')} checked={distributeGroup}/>
                <label className={'form-check-label input-radio-label'} htmlFor="group">
                    {i18n('grouphandler.group')}
                </label>
            </div>
            <div className={'form-check distribute-from'}>
                <input id="section" name="group-or-section" className={'form-check-input'} type="radio"
                       data-bs-toggle="collapse"
                       data-bs-target="#section-filter-from"
                       aria-controls="section-filter-from"
                       aria-expanded={distributeSection ? 'true' : 'false'}
                       value="section_selected" onChange={event => toggleGroupsSections('section')} checked={distributeSection} />
                <label className={'form-check-label input-radio-label'} htmlFor="section">
                    {i18n('grouphandler.section')}
                </label>
            </div>
                <div className={"groups-or-sections"}>
                {distributeGroup && ! distributeSection &&
                    <div id="group-filter-from" className="filter-content card collapse" data-bs-parent="#accordion">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <FilterSelectGroupCategory i18n={i18n} enabled={true} legend={i18n('filter.legend.groupCategories')}
                                                               items={groupssets} groups={groups} onCategoryClick={onGroupCategorySelect}
                                                               groupCategory={filterGroupCategory} filterGroups={filterGroups} setFilterGroups={setFilterGroups}
                                                               onGroupSelect={onGroupSelect} openGroup={openGroupCategory} addGroupFilter={addGroupFilter}
                                                               deleteGroupFilter={deleteGroupFilter} distributeType={"fromgroup-"}
                                                               checked={gc => gc.id === (filterGroupCategory||{}).id} type="checkbox" setFilterGroupsEnabled={setFilterGroupsEnabled}
                                                               />
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {/* Distribute from one or more sections */}
                    { distributeSection && ! distributeGroup &&
                        <div id="section-filter-from" className="filter-content card collapse" data-bs-parent="#accordion">
                            <div className="card-body">
                                <div className="row">
                                    <FilterSelect i18n={i18n} enabled={true} legend={i18n('filter.legend.sections')}
                                                  items={filterSections} setter={setFilterSections} distributeType={"fromsection-"}
                                                  onClick={onSectionSelect} itemType="fromsection" type={"checkbox"}/>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>

            { (distributeGroup || distributeSection) &&
                <div className={'row enrollment-group-div'}>
                    <div className={"col-md"}>
                        <div className="form-label">
                            <strong>2.</strong> {i18n('grouphandler.distribute-to')}
                        </div>
                        <div className={"distribute-from-choice"}>
                            {/* The group filter */}
                            <div className={'form-check distribute-from'}>
                                <input id="to_group" name="to-group-or-section" className={'form-check-input'} type="radio"
                                       data-bs-toggle="collapse"
                                       data-bs-target="#group-filter-to"
                                       aria-controls="group-filter-to"
                                       aria-expanded={distributeToGroups ? 'true' : 'false'}
                                       value="to_group_selected" onChange={event => toggleDistributeTo('group')} checked={distributeToGroups}/>
                                <label className={'form-check-label input-radio-label'} htmlFor="to_group">
                                    {i18n('grouphandler.group')}
                                </label>
                            </div>
                            <div className={'form-check distribute-from'}>
                                <input id="to_section" name="to-group-or-section" className={'form-check-input'} type="radio"
                                       data-bs-toggle="collapse"
                                       data-bs-target="#section-filter-to"
                                       aria-controls="section-filter-to"
                                       aria-expanded={distributeToSections ? 'true' : 'false'}
                                       value="to_section_selected" onChange={event => toggleDistributeTo('section')} checked={distributeToSections} />
                                <label className={'form-check-label input-radio-label'} htmlFor="to_section">
                                    {i18n('grouphandler.section')}
                                </label>
                            </div>
                            <div className={"groups-or-sections"}>
                                {/* Distribute to one or more groups */}
                                {distributeToGroups && ! distributeToSections &&
                                    <div id="group-filter-to" className="filter-content card collapse" data-bs-parent="#accordion">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <FilterSelectGroupCategory i18n={i18n} enabled={true} legend={i18n('filter.legend.groupCategories')}
                                                                               items={groupssets} groups={groups} onCategoryClick={onToGroupCategorySelect}
                                                                               groupCategory={filterToGroupCategory} filterGroups={filterToGroups} setFilterGroups={setFilterToGroups}
                                                                               onGroupSelect={onToGroupSelect} openGroup={openToGroupCategory} addGroupFilter={addToGroupFilter}
                                                                               deleteGroupFilter={deleteToGroupFilter} distributeType={"togroup-"}
                                                                               checked={gc => gc.id === (filterToGroupCategory||{}).id} type="checkbox" setFilterToGroupsEnabled={setFilterToGroupsEnabled}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {/* Distribute to one or more sections */}
                                { ! distributeToGroups && distributeToSections &&
                                    <div id="section-filter-to" className="filter-content card collapse" data-bs-parent="#accordion">
                                        <div className="card-body">
                                            <div className="row">
                                                <FilterSelect i18n={i18n} enabled={true} legend={i18n('filter.legend.sections')}
                                                              items={filterToSections} setter={setFilterToSections}
                                                              onClick={onToSectionSelect} itemType="tosection" type={"checkbox"} distributeType={"tosection-"}/>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }

            <div className="col-md float-left distribute-save">
                <div className={""}>
                    <input type={"checkbox"} name={"reloadParticipations"} id={"reloadParticipations"}/>
                    <label htmlFor={"reloadParticipations"}>{i18n('grouphandler.reload-participations')}</label>
                </div>
                <div className={"save-button"}>
                    <button className={"button-outline save-group"}
                            onClick={(e) => saveDistribution(e)}>{i18n('grouphandler.save')}</button>
                    <p className={"save-text"} id={"save-text"} style={{display:'none'}}>{i18n('grouphandler.saving')}</p>
                    <div className={"spinner-border"} id={"spinner-border"} style={{display:'none'}}/>
                </div>
                <div className="col-md message">
                    <div className="alert alert-success" id={"success-message"} role="alert" style={{ display: 'none' }}>
                        <h3 className="icon-position-left icon-done">{i18n('grouphandler.distribution-saved')}</h3>
                        <p>{i18n('grouphandler.distribution-success-1')}</p>
                    </div>
                    <div className="alert alert-danger" id={"failed-message"} role="alert" style={{ display: 'none' }}>
                        <h3 className="icon-position-left icon-warning">{i18n('grouphandler.distribution-failed-1')}</h3>
                        <p>{i18n('grouphandler.distribution-failed-2')}</p>
                    </div>
                    <div className="alert alert-danger" id={"failed-message-from"} role="alert" style={{ display: 'none' }}>
                        <h3 className="icon-position-left icon-warning">{i18n('grouphandler.distribution-failed-from-header')}</h3>
                        <p>{i18n('grouphandler.distribution-failed-from')}</p>
                    </div>
                    <div className="alert alert-danger" id={"failed-message-to"} role="alert" style={{ display: 'none' }}>
                        <h3 className="icon-position-left icon-warning">{i18n('grouphandler.distribution-failed-to-header')}</h3>
                        <p>{i18n('grouphandler.distribution-failed-to')}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}