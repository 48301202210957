import React from "react";

export default function FilterSelectGroupCategory({i18n, enabled, items = [], label = i => i.name, type='button',
                                       groupCategory, filterGroups, setFilterGroups, onGroupSelect,
                                       addGroupFilter, deleteGroupFilter, openGroup,
                                       name = i => i.id, onCategoryClick, distributeType = ''}) {
    return (
        <div>
            {enabled && <div className="toggle-filter">
                {items.map((item, index) =>
                    <div key={index} className="border-top">
                        <button
                            value={name(item)}
                            onClick={onCategoryClick(item, groupCategory.id)}
                            aria-expanded={openGroup && item.id === groupCategory.id ? "true" : "false"}
                            className={openGroup && item.id === groupCategory.id ?
                                "toggle-button button-text button-icon" : "toggle-button button-text button-icon collapsed"} >{label(item)}
                        </button>
                        {openGroup && item.id === groupCategory.id &&
                            <div className="col-md-12">
                                <div className="row">
                                </div>
                                <div className="row">
                                    <FilterSelectGroup enabled={true}
                                                       i18n={i18n} items={filterGroups} setter={setFilterGroups}
                                                       onClick={onGroupSelect}
                                                       addGroupFilter={addGroupFilter}
                                                       deleteGroupFilter={deleteGroupFilter}
                                                       openGroup={openGroup} distributeType={distributeType}
                                    />
                                </div>
                            </div>
                        }
                    </div>
                )}
            </div>
            }
        </div>

    );
}

function FilterSelectGroup({i18n, enabled, items = [], label = i => i.name, type='checkbox',
                               name = i => i.id, checked = i => i.enabled, setter, onClick,
                               addGroupFilter, deleteGroupFilter, openGroup, distributeType = ''}) {
    let nrEnabled = items.filter((item) => item.enabled).length;
    const allEnabled = nrEnabled === items.length
    const selectAll = e => {
        items.forEach(item => allEnabled ?  deleteGroupFilter(item) : addGroupFilter(item));
        setter(items.map(i => Object.assign({}, i, {enabled: ! allEnabled})));
    }

    return (
        <div className="ms-4">
            {enabled &&
                <fieldset>
                    {type === 'checkbox' && setter && items.length > 1 &&
                        <div className="mb-3">
                            <label>
                                <input type={type}
                                       name={'select.all'}
                                       checked={openGroup && items.filter((item) => item.enabled).length === items.length}
                                       id={"check-all-groups" + distributeType}
                                       onChange={selectAll}>
                                </input>
                                &nbsp; {i18n('filter.select.all')}
                            </label>
                        </div>
                    }
                    <div className="list-columns">
                        <ul className="list-unstyled">
                            {items.map((item, index) =>
                                <div key={index}>
                                    <li key={index} className={item.enabled ? 'nobullet' : ''}>
                                        {/*
                                    <button id={'item-group-' + item.id} className={item.enabled ? 'button-text button-icon icon-clear search-filter-selected' : 'button-text button-icon icon-add search-filter-selected'}
                                            onClick={(e) => onClick(e, item, nrEnabled)}>{label(item)}<span
                                        className="visually-hidden">{i18n('click.to.choose.filter')} {index + 1}</span></button>
                                        */}
                                        <label>
                                            <input type="checkbox"
                                                   id={distributeType + 'item-group-' + item.id}
                                                   value={item.id}
                                                   checked={checked(item)}
                                                   onChange={(e) => onClick(e, item, nrEnabled)}>
                                            </input>
                                            &nbsp; {label(item)}
                                        </label>
                                    </li>
                                </div>
                            )}
                        </ul>
                    </div>
                </fieldset>
            }
        </div>
    );
}