import React, {useState} from "react";
import EnrollmentFilter from "./EnrollmentFilter";
import {initialHeaders} from "./EnrollmentProjection";
import EnrollmentTable from "./EnrollmentTable";
import GroupHandler from "./GroupHandler";


/**
 * Main enrollment view.
 *
 * Owns the state used for configuring the data used in EnrollmentTable:
 *     headers: Specifies the columns used in EnrollmentTable. Configured in EnrollmentProjection.
 *     filters: Specifies how to filter the enrollments in EnrollmentTable. Configured in EnrollmentFilter.
 */
export default function EnrollmentView({i18n, context, participationState, userEmail, isAdmin}) {
    const {course: {name: title='', course_code: label='', id }, sections, programs, groupCategories, groups} = context;
    const tableName = `${i18n('main.header.preamble')}${i18n('main.header.preposition')} ${label} ${title}`;
    const [participations, setParticipations] = participationState;
    const {enrollments, groupMembers, memberGroups} = participations;
    const roles = [ ...(new Set(enrollments.map(e => e.role)))];
    const enrollmentStates = enrollments.length == 0 ? [] : [ ...(new Set(enrollments.map(e => e.enrollment_state)))];
    const reregistered = [ ...(new Set(enrollments.map(e => e.reregistered ? e.reregistered : null).filter(e => e != null)))];
    const [headers, setHeaders] = useState(initialHeaders(i18n));
    const [extraHeadersEnabled, setExtraHeadersEnabled] = useState(false);
    const [extraHeaders, setExtraHeaders] = useState([]);
    const [paperHeaderEnabled, setPaperHeaderEnabled] = useState(false);
    const [filters, setFilters] = useState({sections: null, groups: null, programs: null});

    const [search, setSearch] = React.useState('');
    const handleSearch = (event) => {
        setSearch(event.target.value);
    };
    return (
        <>
            {isAdmin &&
                <nav aria-label="Tabulär navigering">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="V-tab" data-bs-toggle="tab" data-bs-target="#V"
                                    type="button" role="tab" aria-controls="V" aria-selected="true">{i18n('main.header.preamble')}
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="X-tab" data-bs-toggle="tab" data-bs-target="#X" type="button"
                                    role="tab" aria-controls="X" aria-selected="false" tabIndex="-1">{i18n('grouphandler.button')}
                            </button>
                        </li>
                    </ul>
                </nav>
            }
            {isAdmin &&
                <div className="card tab-card tab-content" id="myTabContent">
                    <div className="card-body tab-pane fade active show" id="V" role="tabpanel" aria-labelledby="V-tab">
                        <EnrollmentFilter i18n={i18n} context={context} roles={roles} reregistered={reregistered}
                                          enrollmentStates={enrollmentStates} filterState={[filters, setFilters]}
                                          handleSearch={handleSearch}/>
                        <EnrollmentTable i18n={i18n} context={context} tableName={tableName}
                                         filters={filters} headers={headers} headerState={[headers, setHeaders]}
                                         extraHeadersEnabledState={[extraHeadersEnabled, setExtraHeadersEnabled]}
                                         extraHeadersState={[extraHeaders, setExtraHeaders]}
                                         paperHeaderEnabledState={[paperHeaderEnabled, setPaperHeaderEnabled]}
                                         participations={{enrollments, groupMembers, memberGroups}}
                                         userEmail={userEmail} isAdmin={isAdmin} search={search}/>
                    </div>
                    <div className="card-body tab-pane fade" id="X" role="tabpanel" aria-labelledby="X-tab">
                        <GroupHandler i18n={i18n} groups={groups} sections={sections} groupssets={groupCategories} participationState={{participations, setParticipations}}/>
                    </div>
                </div>
            }
            {! isAdmin &&
                <div>
                <EnrollmentFilter i18n={i18n} context={context} roles={roles} reregistered={reregistered}
                                  enrollmentStates={enrollmentStates} filterState={[filters, setFilters]}
                                  handleSearch={handleSearch}/>
                <EnrollmentTable i18n={i18n} context={context} tableName={tableName}
                                filters={filters} headers={headers} headerState={[headers, setHeaders]}
                                extraHeadersEnabledState={[extraHeadersEnabled, setExtraHeadersEnabled]}
                                extraHeadersState={[extraHeaders, setExtraHeaders]}
                                paperHeaderEnabledState={[paperHeaderEnabled, setPaperHeaderEnabled]}
                                participations={{enrollments, groupMembers, memberGroups}}
                                userEmail={userEmail} isAdmin={isAdmin} search={search}/>
                </div>
            }
        </>
    );
}
