/**
 * I18N messages.
 */
const messages = {
    'common.loading': {
        "sv": 'Laddar...',
        "en": 'Loading...'
    },
    'common.error': {
        "sv": 'Något gick tyvärr fel, ',
        "en": 'Something unexpected occured, '
    },
    'common.error.unauthorized': {
        "sv": 'Du är inte behörig att utföra åtgärden',
        "en": 'You are not authorized to perform this request'
    },
    'main.header.preamble': {
        "sv": 'Studentlista ',
        "en": 'Student list '
    },
    'main.header.preposition': {
        "sv": 'för ',
        "en": 'for '
    },
    'projection.show': {
        "sv": 'Visa kolumner i tabellen',
        "en": 'Columns'
    },
    'filter.reginfo': {
        "sv": 'Registreringsinfo',
        "en": 'Registration info'
    },
    'filter.program': {
        "sv": 'Program',
        "en": 'Programme'
    },
    'filter.select.all': {
        "sv": 'Välj alla',
        "en": 'Select all'
    },
    'filter.roles.enable': {
        "sv": 'Filtrera på roll',
        "en": 'Filter by role'
    },
    'filter.enrollmentStates.enable': {
        "sv": 'Filtrera på status',
        "en": 'Filter by status'
    },
    'filter.section.enable': {
        "sv": 'Filtrera på sektion',
        "en": 'Filter by section'
    },
    'filter.group.enable': {
        "sv": 'Filtrera på gruppuppsättning',
        "en": 'Filter by group set'
    },
    'filter.legend.roles': {
        "sv": 'Roller',
        "en": 'Roles'
    },
    'filter.legend.enrollmentStates': {
        "sv": 'Status',
        "en": 'Status'
    },
    'filter.legend.sections': {
        "sv": 'Sektioner',
        "en": 'Sections'
    },
    'filter.legend.programs': {
        "sv": 'Program',
        "en": 'Programme'
    },
    'filter.legend.groupCategories': {
        "sv": 'Gruppuppsättningar',
        "en": 'Group sets'
    },
    'filter.legend.groups': {
        "sv": 'Grupper',
        "en": 'Groups'
    },
    'projection.paper.header': {
        "sv": 'Rubrik',
        "en": 'Add header'
    },
    'projection.extra.headers': {
        "sv": 'Visa kolumner',
        "en": 'Show columns'
    },
    'projection.extra.paperHeader': {
        "sv": 'Lägg till rubrik:',
        "en": 'Add header:'
    },
    'projection.extra.headers.remove': {
        "sv": 'Ta bort',
        "en": 'Remove'
    },
    'projection.extra.headers.add': {
        "sv": 'Lägg till kolumn',
        "en": 'Add Column'
    },
    'projection.extra.headers.button': {
        "sv": 'Lägg till extra kolumner',
        "en": 'Add extra columns'
    },
    'projection.extra.headers.done': {
        "sv": 'Klar',
        "en": 'Done'
    },
    'table.header.user_name': {
        "sv": 'Namn',
        "en": 'Name'
    },
    'table.header.user_login_id': {
        "sv": 'Personnummer',
        "en": 'Personal identity number'
    },
    'table.header.akkaId': {
        "sv": 'Inloggning',
        "en": 'Login'
    },
    'table.header.sis_user_id': {
        "sv": 'Ladok-ID',
        "en": 'Ladok-ID'
    },
    'table.header.enrollment_state': {
        "sv": 'Avslutad',
        "en": 'Completed'
    },
    'table.header.email': {
        "sv": 'E-post',
        "en": 'Email'
    },
    'table.header.sis_section_id': {
        "sv": 'Sektion',
        "en": 'Section'
    },
    'table.header.role': {
        "sv": 'Roll',
        "en": 'Role'
    },
    'table.header.select_all': {
        "sv": 'Urval',
        "en": 'Selection'
    },
    'table.header_title.select_all': {
        "sv": 'Markera alla',
        "en": 'Select all'
    },
    'table.select_checkbox': {
        "sv": 'Välj',
        "en": 'Select'
    },
    'table.header.group': {
        "sv": 'Grupp',
        "en": 'Group'
    },
    'table.value.role.StudentEnrollment': {
        "sv": 'Student',
        "en": 'Student'
    },
    'table.value.role.Student registrerad': {
        "sv": 'Student registrerad',
        "en": 'Student registrerad'
    },
    'table.value.role.Ej registrerad': {
        "sv": 'Ej registrerad',
        "en": 'Ej registrerad'
    },
    'table.value.role.TeacherEnrollment': {
        "sv": 'Lärare',
        "en": 'Teacher'
    },
    'table.value.role.TaEnrollment': {
        "sv": 'Lärarassistent',
        "en": 'Teacher assistant'
    },
    'table.value.role.ObserverEnrollment': {
        "sv": 'Observatör',
        "en": 'Observer'
    },
    'table.value.role.DesignerEnrollment': {
        "sv": 'Designer',
        "en": 'Designer'
    },
    'table.value.role.Kursadministratör': {
        "sv": 'Kursadministratör',
        "en": 'Kursadministratör'
    },
    'table.value.role.Kursansvarig': {
        "sv": 'Kursansvarig',
        "en": 'Course director'
    },
    'table.value.role.Course Director': {
        "sv": 'Kursansvarig',
        "en": 'Course Director'
    },
    'table.value.role.Deltagare': {
        "sv": 'Deltagare',
        "en": 'Participant'
    },
    'table.value.role.Participant': {
        "sv": 'Deltagare',
        "en": 'Participant'
    },
    'table.value.role.Programadministratör': {
        "sv": 'Programadministratör',
        "en": 'Programme administrator'
    },
    'table.value.role.Programme Administrator': {
        "sv": 'Programadministratör',
        "en": 'Programme administrator'
    },
    'table.value.role.Amanuens läsrättighet': {
        "sv": 'Amanuens läsrättighet',
        "en": 'Amanuens läsrättighet'
    },
    'table.value.role.VFU-handläggare (lärarprogr)': {
        "sv": 'VFU-handläggare (lärarprogr)',
        "en": 'VFU-handläggare (lärarprogr)'
    },
    'table.value.enrollmentState.active': {
        "sv": 'Aktiva studenter',
        "en": 'Active students'
    },
    'table.value.not_enrollmentState.active': {
        "sv": 'Ej aktiva',
        "en": 'Not active'
    },
    'table.value.enrollmentState.invited': {
        "sv": 'Inbjuden',
        "en": 'Invited'
    },
    'table.value.not_enrollmentState.invited': {
        "sv": 'Ej inbjuden',
        "en": 'Not invited'
    },
    'table.value.enrollmentState.inactive': {
        "sv": 'Ej inaktiva',
        "en": 'Not inactive'
    },
    'table.value.enrollmentState.completed': {
        "sv": 'Avslutade',
        "en": 'Completed'
    },
    'table.value.not_enrollmentState.completed': {
        "sv": 'Ej avslutade',
        "en": 'Not completed'
    },
    'table.value.enrollmentState.reregistered': {
        "sv": 'Omregistrerade',
        "en": 'Re-registered'
    },
    'table.header.program': {
        "sv": 'Program',
        "en": 'Programme'
    },
    'table.value.enrollmentState.firsttimeregistered': {
        "sv": 'Förstagångsregistrerade',
        "en": 'First time registered'
    },
    'table.header.reregistered': {
        "sv": 'Omregistrerad',
        "en": 'Re-registered'
    },
    'table.print': {
        "sv": 'Skriv ut/Spara pdf',
        "en": 'Print/Save Pdf'
    },
    'table.csv.export': {
        "sv": 'Exportera',
        "en": 'Export'
    },
    'table.email': {
        "sv": 'Skicka e-post',
        "en": 'Send email'
    },
    'email-failed-dialog.header': {
        "sv": 'Det går inte att öppna ditt e-postprogram',
        "en": 'Cannot open your email client'
    },
    'email-failed-dialog.row1': {
        "sv": 'Prova att använda en annan webbläsare, t.ex. Firefox',
        "en": 'Try to use another browser, e.g. Firefox,'
    },
    'email-failed-dialog.row2': {
        "sv": 'eller kopiera de markerade e-postadresserna och',
        "en": 'or copy the selected email addresses and'
    },
    'email-failed-dialog.row3': {
        "sv": 'klistra in dem i fältet Hemlig kopia i ditt e-postprogram.',
        "en": 'paste them into the Blind copy field in your email client.'
    },
    'email-failed-dialog.copy-email': {
        "sv": 'Kopiera e-postadresser',
        "en": 'Copy email addresses'
    },
    'click.to.choose.filter': {
        "sv": 'Klicka för att välja filterval',
        "en": 'Click to choose filter'
    },
    'filter.click.to.remove': {
        "sv": "Klicka för att ta bort filterval",
        "en": "Click to remove filter"
    },
    'filter.on': {
        "sv": 'Filtrera på',
        "en": 'Filter on'
    },
    'filter.on.role': {
        "sv": 'Roll',
        "en": 'Role'
    },
    'filter.on.status': {
        "sv": 'Status',
        "en": 'Status'
    },
    'filter.on.rereg': {
        "sv": 'Reg/Omreg',
        "en": 'Reg/Re-reg'
    },
    'filter.on.section': {
        "sv": 'Sektion',
        "en": 'Section'
    },
    'filter.on.program': {
        "sv": 'Program',
        "en": 'Programme'
    },
    'filter.on.groups': {
        "sv": 'Gruppuppsättning',
        "en": 'Groups'
    },
    'filter.in.student.list': {
        "sv": 'Filtrera i studentlistan',
        "en": 'Filter Student List'
    },
    'no.group.selected': {
        "sv": 'Inga',
        "en": "No groups"
    },
    'reset.all.filter': {
        "sv": 'Rensa filter',
        "en": "Reset filters"
    },
    'you.have.filtered.on': {
        "sv": 'Du har filtrerat på',
        "en": 'Current filters'
    },
    'filtering': {
        "sv": 'Filtrering',
        "en": 'Filtering'
    },
    'number.of.students': {
        "sv": 'Antal träffar: ',
        "en": 'Number of hits: '
    },
    'show.help': {
        "sv": 'Hjälp',
        "en": 'Help'
    },
    'show.help.header-guide': {
        "sv": 'Guide:',
        "en": 'Student List'
    },
    'show.help.header-lexicon': {
        "sv": 'Lexikon:',
        "en": 'Lexicon:'
    },
    'show.help.all-info': {
        "sv": 'All information hittar du i ',
        "en": 'You find all information in the  '
    },
    'show.help.all-info-link': {
        "sv": 'guiden om studentlistan.',
        "en": 'guide for the student list.'
    },
    'show.help.all-info-link-url': {
        "sv": 'https://uppsala.instructure.com/courses/16238/pages/studentlista',
        "en": 'https://uppsala.instructure.com/courses/16239/pages/student-list'
    },
    'show.help.colheader1': {
        "sv": 'Terminologi i listan',
        "en": 'Terminology in the list'
    },
    'show.help.colheader2': {
        "sv": 'Ladok',
        "en": 'Ladok'
    },
    'show.help.row1': {
        "sv": 'Status',
        "en": 'Status'
    },
    'show.help.row2a': {
        "sv": 'Aktiva studenter',
        "en": 'Active students'
    },
    'show.help.row2b': {
        "sv": 'Alla studenter som inte ännu fått helkursbetyg i Ladok.',
        "en": 'Students who have not yet received a complete course result in Ladok.'
    },
    'show.help.row3a': {
        "sv": 'Avslutade studenter',
        "en": 'Completed (Concluded enrollments)'
    },
    'show.help.row3b': {
        "sv": 'Studenter med helkursbetyg i Ladok.',
        "en": 'Students who have received a complete course result in Ladok.'
    },
    'show.help.row4': {
        "sv": 'Roll',
        "en": 'Role'
    },
    'show.help.row5a': {
        "sv": 'Student registrerad',
        "en": 'Student registrerad'
    },
    'show.help.row5b': {
        "sv": 'Studenter som är registrerade i Ladok.',
        "en": 'Students who are registered in Ladok.'
    },
    'show.help.row6a': {
        "sv": 'Ej registrerad',
        "en": 'Ej registrerad'
    },
    'show.help.row6b': {
        "sv": 'Studenter som är antagna i Ladok.',
        "en": 'Students who are registered in Ladok.'
    }, 'show.help.row7a': {
        "sv": 'Deltagare/Participant',
        "en": 'Participant'
    }, 'show.help.row7b': {
        "sv": 'Roll som går att lägga till på icke-Ladok kurser i Studium. *',
        "en": 'A role which is used in non-Ladok courses. *'
    }, 'show.help.row8a': {
        "sv": 'Student',
        "en": 'Student'
    }, 'show.help.row8b': {
        "sv": 'Icke-Ladok roll som går att använda i manuella sektioner i Studium.',
        "en": 'A non-Ladok role that can be used in manually created sections in Studium.'
    },
    'show.help.row9': {
        "sv": 'Reg/Omreg',
        "en": 'Reg/Re-reg'
    }, 'show.help.row10a': {
        "sv": 'Förstagångsregistrerad',
        "en": 'First time registered'
    }, 'show.help.row10b': {
        "sv": 'Studenter som är registrerade för första gången på kursen.',
        "en": 'Students who are registered to the course for the first time.'
    }, 'show.help.row11a': {
        "sv": 'Omregistrerad',
        "en": 'Re-registered'
    }, 'show.help.row11b': {
        "sv": 'Studenter som är omregistrerade i Ladok.',
        "en": 'Students who have a re-registration to the course in Ladok, i.e. it’s not the first time they take the course.'
    }, 'show.help.read-more': {
        "sv": '* Läs mer om roller i Studium',
        "en": '* Read more about roles in Studium.'
    }, 'show.help.link-roles': {
        "sv": 'https://uppsala.instructure.com/courses/16238/pages/roller-i-studium',
        "en": 'https://uppsala.instructure.com/courses/16239/pages/roles-and-permissions'
    },
    'grouphandler.button': {
        "sv": 'Hantera grupper',
        "en": 'Manage groups'
    },
    'grouphandler.button-close': {
        "sv": 'Stäng',
        "en": 'Close'
    },
    'grouphandler.select-section': {
        "sv": 'Välj sektion',
        "en": 'Select section'
    },
    'grouphandler.select-one-or-more-sections': {
        "sv": 'Välj en eller flera sektioner',
        "en": 'Select one or more sections'
    },
    'grouphandler.select-group': {
        "sv": 'Välj grupp',
        "en": 'Select group'
    },
    'grouphandler.select-group-to': {
        "sv": 'Välj grupp(er)',
        "en": 'Select group'
    },
    'grouphandler.select-group-set': {
        "sv": 'Välj gruppuppsättning',
        "en": 'Select group set'
    },
    'grouphandler.select-group-or-section': {
        "sv": 'Fördela från',
        "en": 'Distribute from'
    },
    'grouphandler.distribute-section': {
        "sv": 'Fördela sektion',
        "en": 'Distribute section'
    },
    'grouphandler.section': {
        "sv": 'Sektion',
        "en": 'Section'
    },
    'grouphandler.sections': {
        "sv": 'Sektioner',
        "en": 'Sections'
    },
    'grouphandler.group-set': {
        "sv": 'Gruppuppsättning',
        "en": 'Group set'
    },
    'grouphandler.group': {
        "sv": 'Grupp',
        "en": 'Group'
    },
    'grouphandler.groups': {
        "sv": 'Grupper',
        "en": 'Groups'
    },
    'grouphandler.distribute-to': {
        "sv": 'Fördela till',
        "en": 'Distribute to'
    },
    'grouphandler.distribute-to-group': {
        "sv": 'Fördela till grupp',
        "en": 'Distribute to group'
    },
    'grouphandler.distribute-to-section': {
        "sv": 'Fördela till sektion',
        "en": 'Distribute to section'
    },
    'grouphandler.distribute-to-groupset': {
        "sv": 'Fördela till gruppuppsättning',
        "en": 'Distribute to group set'
    },
    'grouphandler.distribute': {
        "sv": 'Fördela',
        "en": 'Distribute'
    },
    'grouphandler.to-distribute-to': {
        "sv": ' att fördela till',
        "en": ' to distribute to'
    },
    'grouphandler.distribute-to-group-set': {
        "sv": 'Till gruppuppsättning',
        "en": 'To group set'
    },
    'grouphandler.reload-participations': {
        "sv": 'Ladda om grupper och sektioner i Studentlista',
        "en": 'Reload groups and sections in Student List'
    },
    'grouphandler.save': {
        "sv": 'Spara',
        "en": 'Save'
    },
    'grouphandler.cancel': {
        "sv": 'Avbryt',
        "en": 'Cancel'
    },
    'grouphandler.send': {
        "sv": 'Skicka',
        "en": 'Send'
    },
    'grouphandler.print-message': {
        "sv": 'Skapa och visa gruppuppsättningar, grupper, sektioner',
        "en": 'Create and show group sets, groups, sections'
    },
    'grouphandler.distribution-saved': {
        "sv": 'Sparad!',
        "en": 'Saved!'
    },
    'grouphandler.distribution-success-1': {
        "sv": 'Gå till Studium för att se resultatet av fördelningen.',
        "en": 'Visit Studium to see the result of the distribution.'
    },
    'grouphandler.distribution-failed-1': {
        "sv": "Det har uppstått ett fel!",
        "en": "An error occurred!"
    },
    'grouphandler.distribution-failed-2': {
        "sv": "Ett fel uppstod vid fördelningen",
        "en": "An error occurred during the distribution."
    },
    'grouphandler.saving': {
        "sv": 'Sparar...',
        "en": 'Saving...'
    },
    'grouphandler.distribution-failed-to-header': {
        "sv": 'Information saknas',
        "en": 'Missing information'
    },
    'grouphandler.distribution-failed-from-header': {
        "sv": 'Information saknas',
        "en": 'Missing information'
    },
    'grouphandler.distribution-failed-to': {
        "sv": 'Fyll i grupp eller sektion att fördela till',
        "en": 'Specify group or section to distribute to'
    },
    'grouphandler.distribution-failed-from': {
        "sv": 'Fyll i grupp eller sektion att fördela från',
        "en": 'Specify group or section to distribute from'
    }
}
export default messages;
